import { cls } from "utils";
import "./CaseTagList.css";

function CaseTagList({ list, className }: Props) {
  const classes = "case-tag-list" + cls(className);

  return (
    <ul className={classes}>
      {list.map((tag) => (
        <li key={tag} className="case-tag">
          {tag}
        </li>
      ))}
    </ul>
  );
}

type Props = {
  list: string[];
  className?: string;
};

export default CaseTagList;
