import { Language } from "types";

export function detectLanguage(availableLanguages: string[]) {
  let userLanguage = localStorage.getItem("language");

  if (!userLanguage) {
    let browserLanguage = window.navigator.language;

    if (browserLanguage) {
      userLanguage = browserLanguage.split("-")[0];
    }
  }

  if (!userLanguage || !availableLanguages.includes(userLanguage)) {
    userLanguage = "en" as Language;
  }

  return userLanguage;
}

export function cls(className: string | undefined) {
  return className ? " " + className : "";
}

export function addLineBreaks(string: string, className?: string) {
  const classes = "new-line" + cls(className);

  return string.split("\n").map((line, i, arr) => {
    const space = i === arr.length - 1 ? "" : " ";
    return (
      <span key={i} className={classes}>
        {line + space}
      </span>
    );
  });
}

export function addParagraphs(string: string) {
  return string.split("\n").map((paragraph, i) => {
    return <p key={i}>{paragraph}</p>;
  });
}

export const form = {
  validate: {
    email: function (email: string) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
        ? true
        : false;
    },
    message: function (message: string) {
      return message.length > 3;
    },
    subject: function (subject: string) {
      return subject.length <= 100 && subject.length > 0;
    },
  },
  send: function (
    url: string,
    data: object,
    success: () => void,
    fail: () => void
  ) {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          success();
        } else {
          fail();
        }
        console.log("Success:", result);
      })
      .catch((error) => {
        fail();
        console.error("Error:", error);
      });
    return;
  },
};

export function pad(num: number, size: number = 3) {
  const str = "00" + num;
  return str.slice(-size);
}

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  interval: number
): (...args: Parameters<T>) => ReturnType<T> | undefined {
  let lastCall = 0;
  return function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): ReturnType<T> | undefined {
    const now = Date.now();
    if (lastCall + interval < now) {
      lastCall = now;
      return func.apply(this, args);
    }
  };
}

export function isShort(text: string): boolean {
  if (text.length < 250) return true;
  return false;
}

export function isLong(text: string): boolean {
  if (text.length > 620) return true;
  return false;
}

export function splitByNumber(text: string) {
  const textArr = Array.from(text) as string[];
  const regExp = /[0-9]/;

  const numberStart = textArr.findIndex((char: string) => regExp.test(char));
  if (numberStart === -1) return undefined;

  let numberEnd;
  let i = text.length - 1;

  while (numberEnd === undefined) {
    if (regExp.test(text[i])) numberEnd = i + 1;
    i--;
  }

  const textStart = text.slice(0, numberStart);
  const textNumber = text.slice(numberStart, numberEnd);
  const textEnd = text.slice(numberEnd);

  return {
    start: textStart,
    number: textNumber,
    end: textEnd,
  };
}
