import type { Language } from "types";
import { addLineBreaks } from "utils";
import sections from "translations/sections.json";
import offer from "translations/offer.json";
import Heading from "components/general/Heading";
import Headline from "components/general/Headline";
import OfferList from "./OfferList";
import "./Offer.css";

function Offer({ lang }: Props) {
  const { heading, headline: hl, slug } = sections[lang][2];
  const headline = addLineBreaks(hl!);
  const offerList = offer[lang];

  return (
    <>
      <Heading slug={slug}>{heading}</Heading>
      {/* headline needs box to be sticky within its row only */}
      <div className="offer__headline-box">
        <Headline className="offer__headline">{headline}</Headline>
      </div>
      <OfferList list={offerList} />
    </>
  );
}

type Props = {
  lang: Language;
};

export default Offer;
