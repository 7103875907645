import { cls } from "utils";
import "./SectionName.css";
import Arrow from "./Arrow";

function SectionName({ children, className }: Props) {
  const base = "section-name";
  const classes = base + cls(className);
  return (
    <h2 className={classes}>
      {children}
      <Arrow className={base + "__arrow"} />
    </h2>
  );
}

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default SectionName;
