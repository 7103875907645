import type { Language } from "types";
import sections from "translations/sections.json";
import clients from "translations/clients.json";
import Heading from "components/general/Heading";
import Headline from "components/general/Headline";
import ClientList from "./ClientList";
import ClientsCounter from "./ClientsCounter";
import "./Clients.css";

function Clients({ lang }: Props) {
  const { heading, headline, slug } = sections[lang][4];
  const clientList = clients.clients;

  return (
    <>
      <Heading slug={slug}>{heading}</Heading>
      <Headline className="clients__headline">{headline}</Headline>
      <ClientsCounter number={clientList.length} />
      <ClientList list={clientList} />
    </>
  );
}

type Props = {
  lang: Language;
};

export default Clients;
