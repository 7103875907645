import type { Client } from "types";
import "./ClientListItems.css";

// Logos
import airwick from "images/client-logos/airwick.jpg";
import apart from "images/client-logos/apart.jpg";
import ariel from "images/client-logos/ariel.jpg";
import benefit from "images/client-logos/benefit.jpg";
import empik from "images/client-logos/empik.jpg";
import freudenberg from "images/client-logos/freudenberg.jpg";
import g2a from "images/client-logos/g2a.jpg";
import herbapol from "images/client-logos/herbapol.jpg";
import heviran from "images/client-logos/heviran.jpg";
import ibufen from "images/client-logos/ibufen.jpg";
import krakus from "images/client-logos/krakus.jpg";
import kujawski from "images/client-logos/kujawski.jpg";
import kujawski_pszczolom from "images/client-logos/kujawski_pszczolom.jpg";
import lenor from "images/client-logos/lenor.jpg";
import milka from "images/client-logos/milka.jpg";
import mondelez from "images/client-logos/mondelez.jpg";
import nussbeisser from "images/client-logos/nussbeisser.jpg";
import oliwier from "images/client-logos/oliwier.jpg";
import pampers from "images/client-logos/pampers.jpg";
import pg from "images/client-logos/pg.jpg";
import vileda from "images/client-logos/vileda.jpg";
import vita from "images/client-logos/vita.jpg";
import wolt from "images/client-logos/wolt.jpg";

const logos: { [key: string]: string } = {
  airwick,
  apart,
  ariel,
  benefit,
  empik,
  freudenberg,
  g2a,
  herbapol,
  heviran,
  ibufen,
  krakus,
  kujawski,
  kujawski_pszczolom,
  lenor,
  milka,
  mondelez,
  nussbeisser,
  oliwier,
  pampers,
  pg,
  vileda,
  vita,
  wolt,
};

function ClientListItems({ list }: Props) {
  return (
    <>
      {list.map(({ name, image }, index) => (
        <li key={name + index} className="client">
          <img
            src={logos[image]}
            alt={name}
            className="client__logo"
            decoding="async"
          />
        </li>
      ))}
    </>
  );
}

type Props = {
  list: Client[];
};

export default ClientListItems;
