import { useRef } from "react";
import { useOnScreen } from "components/hooks";
import heartIcon from "images/heart-icon.svg";
import NumberAnimated from "components/animations/NumberAnimated";
import "./ClientsCounter.css";

function ClientsCounter({ number }: Props) {
  const counterRef = useRef(null);
  const isVisible = useOnScreen(counterRef, 1);

  return (
    <div className="clients-counter">
      <div className="clients-counter__heart-box">
        <img
          src={heartIcon}
          alt=""
          className="clients-counter__heart"
          loading="lazy"
          decoding="async"
        />
      </div>
      <p className="clients-counter__number" ref={counterRef}>
        <NumberAnimated animate={isVisible} padSize={3}>
          {String(number)}
        </NumberAnimated>
      </p>
    </div>
  );
}

type Props = {
  number: number;
};

export default ClientsCounter;
