import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useOnScreen } from "components/hooks";
import { pad } from "utils";
import dots from "images/offer-dots.svg";
import icon1 from "images/offer-icons/1.svg";
import icon2 from "images/offer-icons/2.svg";
import icon3 from "images/offer-icons/3.svg";
import icon4 from "images/offer-icons/4.svg";
import icon5 from "images/offer-icons/5.svg";
import icon6 from "images/offer-icons/6.svg";
import icon7 from "images/offer-icons/7.svg";
import icon8 from "images/offer-icons/8.svg";
import "./OfferItem.css";

const icons: { [key: number]: string } = {
  1: icon1,
  2: icon2,
  3: icon3,
  4: icon4,
  5: icon5,
  6: icon6,
  7: icon7,
  8: icon8,
};

function OfferItem({ copy, index: i }: Props) {
  const itemRef = useRef(null);
  const isVisible = useOnScreen(itemRef, 0.5);
  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    if (isVisible) setViewed(true);
  }, [isVisible]);

  return (
    <CSSTransition
      in={isVisible || viewed}
      timeout={1200}
      className="offer-item"
    >
      <li className="offer-item" ref={itemRef}>
        <div className="offer-item__dots-box">
          <img
            src={dots}
            alt=""
            className="offer-item__dots"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="offer-item__icon-box">
          <img
            src={icons[i + 1]}
            alt=""
            className="offer-item__icon"
            loading="lazy"
            decoding="async"
          />
        </div>
        <span className="offer-item__number">{pad(i + 1, 2)} </span>
        <span className="offer-item__copy">{copy}</span>
      </li>
    </CSSTransition>
  );
}

type Props = {
  copy: string;
  index: number;
};

export default OfferItem;
