import { splitByNumber } from "utils";
import CountUpAnimation from "./CountUpAnimation";

function NumberAnimated({
  children,
  animate,
  duration = 2000,
  padSize = 2,
}: Props) {
  const text = splitByNumber(children);
  if (!text) return <>{children}</>;
  const { start, number, end } = text;

  return (
    <>
      {start}
      <CountUpAnimation duration={duration} padSize={padSize} animate={animate}>
        {number}
      </CountUpAnimation>
      {end}
    </>
  );
}

type Props = {
  children: string;
  animate: boolean;
  duration?: number;
  padSize?: number;
};

export default NumberAnimated;
