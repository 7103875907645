import type { Language } from "types";
import sections from "translations/sections.json";
import Heading from "components/general/Heading";
import AboutList from "./AboutList";
import "./About.css";

function About({ lang }: Props) {
  const { heading, slug } = sections[lang][1];

  return (
    <>
      <Heading className="about__heading" slug={slug}>
        {heading}
      </Heading>
      <AboutList lang={lang} />
    </>
  );
}

type Props = {
  lang: Language;
};

export default About;
