import { cls } from "utils";
import "./Separator.css";

function Separator({ solid, className }: Props) {
  const base = "separator";
  const mode = solid ? ` ${base}--solid` : "";
  const classes = base + mode + cls(className);

  return <div className={classes}></div>;
}

type Props = {
  solid?: boolean;
  className?: string;
};

export default Separator;
