import type { Language } from "types";
import Address from "components/general/Address";
import FooterLinks from "./FooterLinks";
import CarlaZuriSeparator from "components/general/CarlaZuriSeparator";
import Copyright from "components/general/Copyright";
import "./Footer.css";

function Footer({ lang }: Props) {
  return (
    <footer className="footer">
      <address className="footer__address">
        <p className="footer__details">
          <Address lang={lang} withDetails />
        </p>
        <FooterLinks lang={lang} />
      </address>
      <CarlaZuriSeparator className="footer__cz-separator" />
      <Copyright lang={lang} className="footer__copyright" />
    </footer>
  );
}

type Props = {
  lang: Language;
};

export default Footer;
