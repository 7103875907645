import { cls } from "utils";
import Arrow from "./Arrow";
import "./Button.css";

function Button({ children, playIcon, className, onClick }: Props) {
  const base = "button";
  const classes = base + cls(className);

  return (
    <button
      className={classes}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <span className={base + "__icon"}>
        {!playIcon && <Arrow className={base + "__arrow"} />}
        {playIcon && (
          <svg
            className={base + "__play"}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="14.016"
            viewBox="0 0 12 14.016"
          >
            <path
              id="Play"
              d="M6.841,4.531A.557.557,0,0,0,6,5.01v12.9a.557.557,0,0,0,.841.479L17.727,11.94a.557.557,0,0,0,0-.958Z"
              transform="translate(-6 -4.453)"
              fill="currentColor"
            />
          </svg>
        )}
      </span>
      <span className={base + "__text"}>{children}</span>
    </button>
  );
}

type Props = {
  children: React.ReactNode;
  playIcon?: boolean;
  className?: string;
  onClick?: () => void;
};

export default Button;
