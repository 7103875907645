import { useState, useEffect } from "react";
import type { Language } from "types";
import { detectLanguage } from "utils";
import sections from "translations/sections.json";
import general from "translations/general.json";
import About from "components/about/About";
import Offer from "components/offer/Offer";
import CaseStudy from "components/case-study/CaseStudy";
import Clients from "components/clients/Clients";
import Reviews from "components/reviews/Reviews";
import Contact from "components/contact/Contact";
import Footer from "components/footer/Footer";
import Section from "components/general/Section";
import SectionGrid from "components/general/SectionGrid";
import SectionName from "components/general/SectionName";
import Separator from "components/general/Separator";
import Showreel from "components/showreel/Showreel";
import MenuBar from "components/menu/MenuBar";
import "App.css";
import "Button.css";

const languages: Language[] = ["pl", "en"];

function App() {
  const [lang, setLang] = useState(detectLanguage(languages) as Language);
  const { description, title } = general[lang];
  const sectionList = sections[lang];

  useEffect(() => {
    document.documentElement.lang = lang;
    document.title = title;
    document
      .querySelector("meta[property='og:title']")!
      .setAttribute("content", title);
    document
      .querySelector("meta[name='description']")!
      .setAttribute("content", description);
    document
      .querySelector("meta[property='og:description']")!
      .setAttribute("content", description);
  }, [lang, description, title]);

  const handleLanguageChange = (altLang: Language) => {
    localStorage.setItem("language", altLang);
    setLang(altLang);
  };

  return (
    <>
      <MenuBar lang={lang} onLanguageChange={handleLanguageChange} />
      <main>
        <Showreel lang={lang} />

        <Section className="section--about">
          <SectionGrid>
            <SectionName className="section-name--about">
              {sectionList[1].name}
            </SectionName>
            <About lang={lang} />
          </SectionGrid>
        </Section>

        <Section className="section--offer" dark>
          <SectionGrid>
            <SectionName className="section-name--offer">
              {sectionList[2].name}
            </SectionName>
            <Offer lang={lang} />
            <Separator />
            <CaseStudy lang={lang} />
          </SectionGrid>
        </Section>

        <Section className="section--clients">
          <SectionGrid>
            <SectionName>{sectionList[4].name}</SectionName>
            <Clients lang={lang} />
            <Separator solid />
            <Reviews lang={lang} />
          </SectionGrid>
        </Section>

        <Section className="section--contact" dark>
          <SectionGrid>
            <SectionName>{sectionList[6].name}</SectionName>
            <Contact lang={lang} />
            <Separator />
            <Footer lang={lang} />
          </SectionGrid>
        </Section>
      </main>
    </>
  );
}

export default App;
