import { pad } from "utils";
import type { CaseStudy, Language } from "types";
import general from "translations/general.json";
import Arrow from "components/general/Arrow";
import "./NextProject.css";

function NextProject({
  lang,
  slim,
  caseData,
  caseIndex,
  onNextCaseClick,
}: Props) {
  const { nextProjectLabel } = general[lang];

  return (
    <button className="np-btn" onClick={() => onNextCaseClick()}>
      <span className="np-btn__row">
        <span className="np-btn__nr">{pad(caseIndex + 1, 2)}</span>
        <span className="np-btn__label">{nextProjectLabel}</span>
      </span>
      <span className="np-btn__row np-btn__heading">
        <Arrow className="np-btn__arrow" slim={slim} />
        <span className="np-btn__heading-text">{caseData.title}</span>
      </span>
    </button>
  );
}

type Props = {
  lang: Language;
  caseData: CaseStudy;
  caseIndex: number;
  onNextCaseClick: () => void;
  slim?: boolean;
};

export default NextProject;
