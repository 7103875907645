import type { Client } from "types";
import ClientListRow from "./ClientListRow";
import "./ClientList.css";

function ClientList({ list }: Props) {
  return (
    <div className="client-list-box">
      <ul className="client-list">
        <ClientListRow list={list.filter((item, i) => i < 8)} />
        <ClientListRow list={list.filter((item, i) => i > 7 && i < 16)} />
        <ClientListRow list={list.filter((item, i) => i > 15 && i < 24)} />
      </ul>
    </div>
  );
}

type Props = {
  list: Client[];
};

export default ClientList;
