import { useEffect, useState } from "react";
import { pad } from "utils";

const easeOutQuad = (t: number) => t * (2 - t);
const frameDuration = 1000 / 60;

function CountUpAnimation({
  children,
  animate,
  duration = 2000,
  padSize,
}: Props) {
  const countTo = parseInt(children, 10);
  const [count, setCount] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (animate && !done) {
      let frame = 0;
      const totalFrames = Math.round(duration / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setCount(countTo * progress);

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);

      setDone(true);
    }
  }, [countTo, duration, animate, done]);

  return (
    <span aria-live="polite" aria-busy={count === countTo ? "false" : "true"}>
      {padSize !== undefined
        ? pad(Math.floor(count), padSize)
        : Math.floor(count)}
    </span>
  );
}

type Props = {
  children: string;
  animate: boolean;
  duration?: number;
  padSize?: number;
};

export default CountUpAnimation;
