import { KeyboardEvent, useState } from "react";
import type { CaseStudy, Language } from "types";
import general from "translations/general.json";
import CaseCard from "./CaseCard";
import "./CaseList.css";

function CaseList({ list, onCaseClick, onCasePress, lang }: Props) {
  const { showAllLabel } = general[lang];
  const [isShowAll, setIsShowAll] = useState(false);
  const initialNumberOfCases = 6;
  const numberOfCases = list.length;

  return (
    <>
      <ul className="case-list">
        {list.map((caseItem, i) => {
          if (!isShowAll && i >= initialNumberOfCases) return "";

          return (
            <CaseCard
              key={caseItem.title}
              item={caseItem}
              onCaseClick={() => onCaseClick(i)}
              onCasePress={(event: KeyboardEvent) => onCasePress(event, i)}
            />
          );
        })}
      </ul>

      <div className="case-list__more-button-container">
        {numberOfCases > initialNumberOfCases && (
          <button
            className="button-nav button-nav--light case-list__more-button"
            onClick={() => setIsShowAll(true)}
            disabled={isShowAll}
          >
            {showAllLabel}
          </button>
        )}
      </div>
    </>
  );
}

type Props = {
  list: CaseStudy[];
  onCaseClick: (arg0: number) => void;
  onCasePress: (event: KeyboardEvent, arg0: number) => void;
  lang: Language;
};

export default CaseList;
