import { cls } from "utils";
import "./Headline.css";

function Headline({ children, className }: Props) {
  const classes = "headline" + cls(className);
  return <h4 className={classes}>{children}</h4>;
}

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default Headline;
