import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import type { Language, Review } from "types";
import general from "translations/general.json";
import ReviewSlide from "./ReviewSlide";
import Arrow from "components/general/Arrow";
import "./ReviewSlider.css";

function ReviewSlider({ list, lang }: Props) {
  const { nextLabel, prevLabel, moreLabel } = general[lang];
  const [current, setCurrent] = useState(0);
  const [next, setNext] = useState(true);
  const max = list.length - 1;

  const handleBulletClick = (i: number) => {
    setCurrent(i);
  };

  const handleNext = () => {
    setCurrent((old) => {
      if (current === max) return old;
      return old + 1;
    });
    setNext(true);
  };

  const handlePrev = () => {
    setCurrent((old) => {
      if (current === 0) return old;
      return old - 1;
    });
    setNext(false);
  };

  const inactiveClass = "slider-nav__button--inactive";
  const inactivePrevClass = current === 0 ? ` ${inactiveClass}` : "";
  const inactiveNextClass = current === max ? ` ${inactiveClass}` : "";

  return (
    <>
      <nav className="slider-nav">
        <button
          className="slider-nav__button-box"
          onClick={handlePrev}
          aria-label={prevLabel}
        >
          <div className={"slider-nav__button" + inactivePrevClass}>
            <Arrow slim className="slider-nav__arrow slider-nav__arrow--prev" />
          </div>
        </button>
        <button
          className="slider-nav__button-box"
          onClick={handleNext}
          aria-label={nextLabel}
        >
          <div className={"slider-nav__button" + inactiveNextClass}>
            <Arrow slim className="slider-nav__arrow slider-nav__arrow--next" />
          </div>
        </button>
      </nav>

      {list.map((item, i) => {
        return (
          <CSSTransition
            key={i}
            in={i === current}
            timeout={300}
            className="review"
            mountOnEnter
            unmountOnExit
          >
            <ReviewSlide
              item={item}
              onNext={handleNext}
              onPrev={handlePrev}
              next={next}
              moreLabel={moreLabel}
            />
          </CSSTransition>
        );
      })}

      <ul className="slider-bullets">
        {list.map((item, i) => {
          const active = i === current ? " slider-bullet--active" : "";
          const classes = "slider-bullet" + active;
          return (
            <li
              key={item.author}
              className={classes}
              onClick={() => {
                handleBulletClick(i);
              }}
            ></li>
          );
        })}
      </ul>
    </>
  );
}

type Props = {
  list: Review[];
  lang: Language;
};

export default ReviewSlider;
