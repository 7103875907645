import { useRef, useState, useEffect } from "react";
import NumberAnimated from "components/animations/NumberAnimated";
import "./AboutItem.css";
import { useOnScreen } from "components/hooks";

function AboutItem({ item, index: i }: Props) {
  const itemRef = useRef(null);
  const isVisible = useOnScreen(itemRef, 0.5);
  const [viewed, setViewed] = useState(false);
  const [docLoaded, setDocLoaded] = useState(false);

  window.addEventListener("load", () => {
    setDocLoaded(true);
  });

  useEffect(() => {
    if (isVisible) setViewed(true);
  }, [isVisible]);

  return (
    <>
      {docLoaded && (
        <li
          className={"about-item" + (isVisible || viewed ? " enter-done" : "")}
        >
          <p className="about-item__headline" ref={itemRef}>
            {i < 3 && <>{item.headline}</>}
            {i >= 3 && (
              <NumberAnimated duration={1000} animate={isVisible}>
                {item.headline}
              </NumberAnimated>
            )}
          </p>

          <p className="about-item__paragraph">{item.paragraph}</p>
        </li>
      )}
    </>
  );
}

type Props = {
  item: {
    headline: string;
    paragraph: React.ReactNode;
  };
  index: number;
};

export default AboutItem;
