import type { CaseStudy } from "types";
import "./Gallery.css";

// All images
import kujawski_01 from "images/case-studies/kujawski_01.jpg";
import kujawski_02 from "images/case-studies/kujawski_02.jpg";
import recman from "images/case-studies/recman.jpg";
import recman_1 from "images/case-studies/recman_1.jpg";
import recman_2 from "images/case-studies/recman_2.jpg";
import somat_stb_01 from "images/case-studies/somat_stb_01.jpg";
import somat_stb_02 from "images/case-studies/somat_stb_02.jpg";
import somat_stb_03 from "images/case-studies/somat_stb_03.jpg";
import somat_stb_04 from "images/case-studies/somat_stb_04.jpg";
import somat_stb_05 from "images/case-studies/somat_stb_05.jpg";

const imgs: { [key: string]: string } = {
  kujawski_01,
  kujawski_02,
  recman,
  recman_1,
  recman_2,
  somat_stb_01,
  somat_stb_02,
  somat_stb_03,
  somat_stb_04,
  somat_stb_05,
};

function Gallery({ caseData }: Props) {
  const { media } = caseData;

  return (
    <div className="cs-gallery">
      {media.map((image) => {
        const videoElement = (
          <video
            src={"/videos/" + image.name + ".mp4"}
            controls
            className="cs-gallery__video"
          ></video>
        );

        const imageElement = (
          <img
            src={imgs[image.name]}
            alt={image.name}
            className="cs-gallery__image"
            loading="lazy"
            decoding="async"
          />
        );

        return (
          <div
            key={image.name}
            className={
              "cs-gallery__image-box cs-gallery__image-box--" + image.size
            }
          >
            {image.type === "image" ? imageElement : videoElement}
          </div>
        );
      })}
    </div>
  );
}

type Props = {
  caseData: CaseStudy;
};

export default Gallery;
