import type { Language } from "types";
import { addLineBreaks } from "utils";
import about from "translations/about.json";
import AboutItem from "./AboutItem";
import "./AboutList.css";

function AboutList({ lang }: Props) {
  const aboutList = about[lang].map((item) => ({
    headline: item.headline,
    paragraph: addLineBreaks(item.paragraph),
  }));

  return (
    <ul className="about-list">
      {aboutList.map((item, i) => (
        <AboutItem key={i} index={i} item={item} />
      ))}
    </ul>
  );
}

type Props = {
  lang: Language;
};

export default AboutList;
