import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import type { Language } from "types";
import general from "translations/general.json";
import sections from "translations/sections.json";
import Separator from "components/general/Separator";
import Arrow from "components/general/Arrow";
import Button from "components/general/Button";
import poster from "images/teaser.jpg";
import "./Header.css";
import "./Showreel.css";

function Showreel({ lang }: Props) {
  const [showreelIsActive, setShowreelIsActive] = useState(false);
  const [docLoaded, setDocLoaded] = useState(false);
  const [posterIsVisible, setPosterIsVisible] = useState(true);
  const { scrollLabel, watchLabel, closeLabel } = general[lang];
  const { slug } = sections[lang][0];
  const { slug: slugAbout } = sections[lang][1];
  const hide = showreelIsActive ? " header__hidden" : "";
  const show = !showreelIsActive ? " header__hidden" : "";
  const active = showreelIsActive ? " showreel-box--active" : "";

  const handleWatchClick = () => {
    setShowreelIsActive(true);
    const teaser = document.querySelector(".js-teaser") as HTMLVideoElement;
    if (!teaser) return;
    teaser.play();
  };

  const handleCloseClick = () => {
    setShowreelIsActive(false);
  };

  // fix video autoplay
  window.addEventListener("load", () => {
    setDocLoaded(true);
  });

  const handleVidLoad = () => {
    setPosterIsVisible(false);
  };

  return (
    <header className="header" id={slug}>
      <div className={"showreel-box" + active}>
        {docLoaded && (
          <video
            src="/videos/video_desktop.mp4"
            loop
            muted={!showreelIsActive}
            autoPlay
            controls={showreelIsActive}
            playsInline
            webkit-playsinline="true"
            className="showreel__teaser js-teaser"
            onCanPlay={handleVidLoad}
          ></video>
        )}
        <CSSTransition
          in={posterIsVisible}
          timeout={1000}
          className="showreel__poster"
          unmountOnExit
        >
          <img src={poster} alt="" className="showreel__poster" />
        </CSSTransition>
      </div>

      <Button
        className={"header__watch" + hide}
        playIcon
        onClick={handleWatchClick}
      >
        {watchLabel}
      </Button>
      <Separator className={"header__separator" + hide} />
      <a
        href={"#" + slugAbout}
        className={"header__scroll" + hide}
        aria-labelledby="scroll-label"
      >
        <span className="header__arrow-box">
          <span className="header__arrow-anim-box">
            <Arrow className="header__arrow" />
          </span>
        </span>
        <span className="header__scroll-info" id="scroll-label">
          {scrollLabel}
        </span>
      </a>

      <button
        aria-label={closeLabel}
        className={"showreel__button button-nav-hover" + show}
        onClick={handleCloseClick}
      >
        <span className="button-nav button-nav--light button-nav--icon">
          <span className="button-nav-icon button-nav-icon--close"></span>
        </span>
      </button>
    </header>
  );
}

type Props = {
  lang: Language;
};

export default Showreel;
