import Arrow from "components/general/Arrow";
import "./MenuSection.css";

function MenuSection({
  name,
  links,
  newTab,
  onSectionLinkClick,
  isMenuExpanded,
}: Props) {
  return (
    <li className="menu-section">
      <span className="menu-section__name">{name}</span>

      <ul className="menu-section__heading-list">
        {links.map((link, i) => {
          const linkAttributes: LinkAttributes = {
            href: link.slug,
            className: "menu-section__link",
            tabIndex: isMenuExpanded ? undefined : -1,
          };

          if (newTab) {
            linkAttributes.target = "_blank";
            linkAttributes.rel = "noopener noreferrer";
          }

          if (onSectionLinkClick) {
            linkAttributes.onClick = () => onSectionLinkClick();
          }

          return (
            <li className="menu-section__heading" key={i}>
              <a {...linkAttributes}>
                <Arrow className="menu-section__arrow" />
                <span className="menu-section__link-text">{link.heading}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

type Props = {
  name: string;
  links: {
    slug: string;
    heading: string;
  }[];
  isMenuExpanded: boolean;
  newTab?: boolean;
  onSectionLinkClick?: () => void;
};

type LinkAttributes = {
  href: string;
  className: string;
  tabIndex?: number;
  target?: string;
  rel?: string;
  onClick?: () => void;
};

export default MenuSection;
