import FocusTrap from "focus-trap-react";
import type { CaseStudy, Language } from "types";
import { addParagraphs, pad } from "utils";
import general from "translations/general.json";
import Details from "./Details";
import NextProject from "./NextProject";
import Gallery from "./Gallery";
import Separator from "components/general/Separator";
import CarlaZuriSeparator from "components/general/CarlaZuriSeparator";
import "./CaseStudyModal.css";

function CaseStudyModal({
  lang,
  onModalClose,
  onOverlayClick,
  caseData,
  caseIndex,
  nextCase,
  nextCaseIndex,
  onNextCaseClick,
}: Props) {
  const { closeLabel } = general[lang];
  const description = addParagraphs(caseData.description);

  return (
    <FocusTrap>
      <div
        className="js-modal-overlay cs-modal-overlay"
        onClick={onOverlayClick}
      >
        <article className="cs-modal">
          <button
            aria-label={closeLabel}
            className="cs-modal__button button-nav-hover"
            onClick={onModalClose}
          >
            <span className="button-nav button-nav--dark button-nav--icon">
              <span className="button-nav-icon button-nav-icon--close"></span>
            </span>
          </button>

          <header className="cs-modal__header">
            <p className="cs-modal__number">{pad(caseIndex + 1, 2)}</p>
            <h2 className="cs-modal__heading">{caseData.title}</h2>
          </header>

          <NextProject
            lang={lang}
            caseData={nextCase}
            caseIndex={nextCaseIndex}
            onNextCaseClick={() => onNextCaseClick(nextCaseIndex)}
          />
          <Separator className="cs-modal__separator" />

          <div className="cs-modal__description">{description}</div>

          <Details lang={lang} caseData={caseData} />
          <Gallery caseData={caseData} />
          <CarlaZuriSeparator className="cs-modal__cz-separator" />
          <NextProject
            lang={lang}
            caseData={nextCase}
            caseIndex={nextCaseIndex}
            onNextCaseClick={() => onNextCaseClick(nextCaseIndex)}
            slim
          />
        </article>
      </div>
    </FocusTrap>
  );
}

type Props = {
  lang: Language;
  onModalClose: () => void;
  onOverlayClick: (event: React.MouseEvent) => void;
  onNextCaseClick: (arg0: number) => void;
  caseData: CaseStudy;
  nextCase: CaseStudy;
  caseIndex: number;
  nextCaseIndex: number;
};

export default CaseStudyModal;
