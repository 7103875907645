import type { Language } from "types";
import sections from "translations/sections.json";
import reviews from "translations/reviews.json";
import Heading from "components/general/Heading";
import Headline from "components/general/Headline";
import ReviewSlider from "./ReviewSlider";
import "./Reviews.css";

function Reviews({ lang }: Props) {
  const { heading, headline, slug } = sections[lang][5];
  const reviewList = reviews[lang];

  return (
    <>
      <Heading slug={slug}>{heading}</Heading>
      <Headline className="reviews__headline">{headline}</Headline>
      <ReviewSlider list={reviewList} lang={lang} />
    </>
  );
}

type Props = {
  lang: Language;
};

export default Reviews;
