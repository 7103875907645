import { cls } from "utils";
import "./Section.css";

function Section({ children, className, dark }: Props) {
  const base = "section";
  const mode = dark ? ` ${base}--dark` : "";
  const classes = base + mode + cls(className);

  return <section className={classes}>{children}</section>;
}

type Props = {
  children: React.ReactNode;
  className?: string;
  dark?: boolean;
};

export default Section;
