import { cls } from "utils";
import "./Heading.css";

function Heading({ children, slug, className }: Props) {
  const classes = "heading" + cls(className);
  return (
    <h3 className={classes} id={slug}>
      {children}
    </h3>
  );
}

type Props = {
  children: React.ReactNode;
  slug?: string;
  className?: string;
};

export default Heading;
