import type { Language } from "types";
import person from "translations/contact-person.json";
import image from "images/contact-person.png";
import dots from "images/contact-person-dots.svg";
import "./ContactPerson.css";

function ContactPerson({ lang }: Props) {
  const { firstName, lastName, position, email, phoneNumber } = person[lang];
  return (
    <address className="person">
      <div className="person__image-box person__image-box--dots">
        <img src={dots} alt="" loading="lazy" decoding="async" />
      </div>
      <div className="person__image-box">
        <img src={image} alt="" loading="lazy" decoding="async" />
      </div>
      <div className="person__details">
        <span className="person__name">{firstName} </span>
        <span className="person__name">{lastName} </span>
        <span className="person__detail person__detail--position">
          {position}
        </span>
        <span className="person__detail person__detail--email">
          <a href={"mailto:" + email} className="person__link">
            {email}
          </a>{" "}
        </span>
        <span className="person__detail">
          <a href={"tel:" + phoneNumber} className="person__link">
            {phoneNumber}
          </a>
        </span>
      </div>
    </address>
  );
}

type Props = {
  lang: Language;
};

export default ContactPerson;
