import type { Client } from "types";
import "./ClientListRow.css";
import ClientListItems from "./ClientListItems";

function ClientListRow({ list }: Props) {
  return (
    <div className="client-list__row">
      <div className="client-list__items">
        <ClientListItems list={list} />
      </div>
      <div className="client-list__items client-list__items--clone">
        <ClientListItems list={list} />
      </div>
    </div>
  );
}

type Props = {
  list: Client[];
};

export default ClientListRow;
