import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import type { Review } from "types";
import { addLineBreaks, addParagraphs, isLong, isShort } from "utils";
import quote from "images/quote.svg";
import triangle from "images/slider-triangle.svg";
import Arrow from "components/general/Arrow";
import "./ReviewSlide.css";

// Logos

import pg from "images/review-logos/pg.jpg";
import mondelez from "images/review-logos/mondelez.jpg";
import ppp from "images/review-logos/ppp.jpg";
import lego from "images/review-logos/lego.jpg";
import bunge from "images/review-logos/bunge.svg";

const logos: { [key: string]: string } = {
  pg,
  mondelez,
  ppp,
  lego,
  bunge,
};

function ReviewSlide({ item, onNext, onPrev, next, moreLabel }: Props) {
  const [expanded, setExpanded] = useState(false);
  const { author, company, position: pos, image, review: rv } = item;
  const review = addParagraphs(rv);
  const position = addLineBreaks(pos);
  const shortReview = isShort(rv);
  const longReview = isLong(rv);

  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
  });

  return (
    <article className={"review" + (next ? " next" : " prev")} {...handlers}>
      <div className="review__icon-box">
        <img
          src={quote}
          alt=""
          className="review__icon"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div className="review__box">
        <div className="review__triangle-box">
          <img
            src={triangle}
            alt=""
            className="review__triangle"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="review__image-box">
          <img
            src={logos[image]}
            alt={company}
            loading="lazy"
            decoding="async"
          />
        </div>
        <p className="review__author">
          <span className="review__author-name">{author}</span>
          <span className="review__author-position">{position}</span>
        </p>
      </div>
      <div
        className={
          "review__content" +
          (shortReview ? " review__content--short" : "") +
          (longReview ? " review__content--long" : "") +
          (expanded ? " review__content--expanded" : "")
        }
      >
        <div>
          {review}
          {longReview && (
            <button
              className="review__expand-button"
              onClick={() => setExpanded(true)}
            >
              {moreLabel} <Arrow className="review__arrow" />
            </button>
          )}
        </div>
      </div>
    </article>
  );
}

type Props = {
  item: Review;
  onNext: () => void;
  onPrev: () => void;
  next: boolean;
  moreLabel: string;
};

export default ReviewSlide;
