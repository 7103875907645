import type { KeyboardEvent } from "react";
import { useRef, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import type { CaseStudy } from "types";
import { useOnScreen } from "components/hooks";
import CaseTagList from "./CaseTagList";
import Arrow from "components/general/Arrow";
import "./CaseCard.css";

// Images
import g2a_cover from "images/case-studies/g2a_cover.jpg";
import pampers_cover2 from "images/case-studies/pampers_cover2.jpg";
import kujawski_cover from "images/case-studies/kujawski_02.jpg";
import herbalove_cover from "images/case-studies/herbalove_cover.jpg";
import recman_2 from "images/case-studies/recman_2.jpg";
import somat_stb_01 from "images/case-studies/somat_stb_01.jpg";

const imgs: { [key: string]: string } = {
  g2a_cover,
  pampers_cover2,
  kujawski_cover,
  herbalove_cover,
  recman_2,
  somat_stb_01,
};

function CaseCard({ item, onCaseClick, onCasePress }: Props) {
  const { brand, tags, cover } = item;
  const itemRef = useRef(null);
  const isVisible = useOnScreen(itemRef, 0.2);
  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    if (isVisible) setViewed(true);
  }, [isVisible]);

  return (
    <CSSTransition
      in={isVisible || viewed}
      timeout={1400}
      className="case-card"
    >
      <li
        className="case-card"
        onClick={() => onCaseClick()}
        onKeyDown={(event) => onCasePress(event)}
        tabIndex={0}
        ref={itemRef}
      >
        <div className="case-card__image-box">
          <img
            src={imgs[cover.name]}
            alt={cover.alt}
            className={
              "case-card__image" +
              (cover.position === "top" ? " case-card__image--top" : "")
            }
            loading="lazy"
            decoding="async"
          />
        </div>
        <div className="case-card__row">
          <span className="case-card__brand">{brand}</span>
          <Arrow className="case-card__arrow" />
        </div>
        <CaseTagList list={tags} />
      </li>
    </CSSTransition>
  );
}

type Props = {
  item: CaseStudy;
  onCaseClick: () => void;
  onCasePress: (event: KeyboardEvent) => void;
};

export default CaseCard;
