import type { CaseStudy, Language } from "types";
import general from "translations/general.json";
import CaseTagList from "components/case-study/CaseTagList";
import "./Details.css";

function Details({ lang, caseData }: Props) {
  const { clientLabel, brandLabel, industryLabel, roleLabel } = general[lang];

  return (
    <div className="cs-details">
      <p className="cs-detail">
        <span className="cs-detail__heading">{clientLabel}: </span>
        <span className="cs-detail__content">{caseData.client}</span>
      </p>
      <p className="cs-detail">
        <span className="cs-detail__heading">{brandLabel}: </span>
        <span className="cs-detail__content">{caseData.brand}</span>
      </p>
      <p className="cs-detail">
        <span className="cs-detail__heading">{industryLabel}: </span>
        <span className="cs-detail__content">{caseData.industry}</span>
      </p>
      <span className="cs-detail">
        <span className="cs-detail__heading">{roleLabel}: </span>
        <span className="cs-detail__content">
          <CaseTagList className="cs-detail__tag-list" list={caseData.tags} />
        </span>
      </span>
    </div>
  );
}

type Props = {
  lang: Language;
  caseData: CaseStudy;
};

export default Details;
