import type { Language } from "types";
import company from "translations/company.json";
import socials from "translations/social-media.json";
import "./FooterLinks.css";

function FooterLinks({ lang }: Props) {
  const links = socials[lang];

  return (
    <ul className="footer-links">
      <li className="footer-link">
        <a
          href={"mailto:" + company[lang].email}
          className="footer-link__anchor underline-hover"
        >
          {company[lang].email}
        </a>
      </li>

      {links.map((link) => (
        <li key={link.name} className="footer-link">
          <a
            href={link.url}
            className="footer-link__anchor underline-hover"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.name}
          </a>
        </li>
      ))}
    </ul>
  );
}

type Props = {
  lang: Language;
};

export default FooterLinks;
