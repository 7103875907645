import { useEffect, useState, MutableRefObject } from "react";

const useOnScreen = (
  ref: MutableRefObject<HTMLDivElement | null>,
  threshold: number = 0
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      {
        root: null,
        rootMargin: "0px",
        threshold: threshold,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  });
  return isIntersecting;
};

export default useOnScreen;
