import { cls } from "utils";
import "./Arrow.css";

function Arrow({ className, slim }: Props) {
  const base = "arrow";
  const mode = slim ? ` ${base}--slim` : "";
  const classes = base + mode + cls(className);

  return (
    <span className={classes}>
      <span className={base + "__view-box"}>
        {!slim && (
          <svg
            className={base + "__svg"}
            xmlns="http://www.w3.org/2000/svg"
            width="67.541"
            height="16.428"
            viewBox="0 0 67.541 16.428"
          >
            <path
              data-name="arrow"
              d="m 58.286001,0.2134995 a 6.676,6.676 0 0 0 1.686,3.1770004 14.979,14.979 0 0 0 3.887,3.481 7.781,7.781 0 0 0 3.569999,1.3420002 m 0,0 a 7.776,7.776 0 0 0 -3.57,1.342 15.037,15.037 0 0 0 -3.887,3.4799999 6.694,6.694 0 0 0 -1.686,3.177999 M 67.429,8.2135001 H 0"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        )}
        {slim && (
          <svg
            className={base + "__svg"}
            xmlns="http://www.w3.org/2000/svg"
            width="47.429"
            height="24"
            viewBox="0 0 47.429 24"
          >
            <path
              data-name="arrow-slim"
              d="M13.714,1.5c0,1.269,1.257,3.167,2.528,4.761a22.526,22.526,0,0,0,5.831,5.215,11.683,11.683,0,0,0,5.355,2.009M13.714,25.5c0-1.269,1.257-3.167,2.528-4.761a22.526,22.526,0,0,1,5.831-5.215,11.683,11.683,0,0,1,5.355-2.009m0-.015H-20"
              transform="translate(20 -1.5)"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        )}
      </span>
    </span>
  );
}

type Props = {
  className?: string;
  slim?: boolean;
};

export default Arrow;
