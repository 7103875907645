import { useState } from "react";
import type { Language } from "types";
import general from "translations/general.json";
import sections from "translations/sections.json";
import logo from "images/carla-zuri-logo.svg";
import subline from "images/carla-zuri-subline.svg";
import MenuExpand from "./MenuExpand";
import "./MenuBar.css";

function MenuBar({ lang, onLanguageChange }: Props) {
  const { slug: slugTop } = sections[lang][0];
  const { slug: slugContact } = sections[lang][6];
  const { contactLabel } = general[lang];
  const [menuIsActive, setMenuIsActive] = useState(false);
  const altLang = lang === "pl" ? "en" : "pl";

  const handleMenuExpand = () => {
    setMenuIsActive(!menuIsActive);
  };

  const handleSectionLinkClick = () => {
    setMenuIsActive(false);
  };

  return (
    <header className={"menu-bar" + (menuIsActive ? " menu-bar--active" : "")}>
      <MenuExpand
        lang={lang}
        menuIsActive={menuIsActive}
        onMenuExpand={handleMenuExpand}
        onSectionLinkClick={handleSectionLinkClick}
      />

      <a
        href={"#" + slugTop}
        onClick={handleSectionLinkClick}
        className="logo-link"
      >
        <div className="logo-combined">
          <div className="logo-box">
            <img
              src={logo}
              alt="Carla Zuri"
              className="logo"
              width="223.77px"
              height="54.7px"
            />
          </div>
          <div className="subline-box">
            <img
              src={subline}
              alt=""
              className="subline"
              width="149.63px"
              height="10.42px"
            />
          </div>
        </div>
      </a>

      <nav className="side-links">
        <a
          href={"#" + slugContact}
          className="button-nav button-nav--light side-link side-link--contact"
          onClick={handleSectionLinkClick}
        >
          {contactLabel}
        </a>
        <button
          className="button-nav button-nav--light side-link side-link--lang"
          onClick={() => onLanguageChange(altLang)}
        >
          {altLang}
        </button>
      </nav>
    </header>
  );
}

type Props = {
  lang: Language;
  onLanguageChange: (altLang: Language) => void;
};

export default MenuBar;
