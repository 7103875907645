import "./SectionGrid.css";

function SectionGrid({ children }: Props) {
  return <div className="section-grid">{children}</div>;
}

type Props = {
  children: React.ReactNode;
};

export default SectionGrid;
