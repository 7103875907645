import OfferItem from "./OfferItem";
import "./OfferList.css";

function OfferList({ list }: Props) {
  return (
    <ul className="offer-list">
      {list.map((copy, i) => (
        <OfferItem copy={copy} index={i} key={i} />
      ))}
    </ul>
  );
}

type Props = {
  list: string[];
};

export default OfferList;
