import type { KeyboardEvent } from "react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import type { Language } from "types";
import sections from "translations/sections.json";
import cases from "translations/case-studies.json";
import Heading from "components/general/Heading";
import Headline from "components/general/Headline";
import CaseList from "./CaseList";
import CaseStudyModal from "components/case-study-modal/CaseStudyModal";
import "./CaseStudy.css";

function CaseStudy({ lang }: Props) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [caseIndex, setCaseIndex] = useState(0);
  const { heading, headline, slug } = sections[lang][3];
  const caseList = cases[lang];
  const timeout = 200;

  const lockPageScroll = () => {
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
    document.body.style.margin = "0 " + scrollBarWidth + "px 0 0";
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty(
      "--js-scrollbar",
      scrollBarWidth + "px"
    );
  };

  const unlockPageScroll = () => {
    setTimeout(() => {
      document.body.style.margin = "0";
      document.body.style.overflow = "";
      document.documentElement.style.setProperty("--js-scrollbar", "0px");
    }, timeout);
  };

  if (modalIsVisible) lockPageScroll();
  else unlockPageScroll();

  const handleCaseClick = (i: number) => {
    setCaseIndex(i);
    setModalIsVisible(true);
  };

  const handleCasePress = (event: KeyboardEvent, i: number) => {
    if (event.key === "Enter" || event.key === " ") {
      setCaseIndex(i);
      setModalIsVisible(true);
    }
  };

  const handleModalClose = () => {
    setModalIsVisible(false);
  };

  const handleOverlayClick = (event: React.MouseEvent) => {
    const modalOverlay = document.querySelector(".js-modal-overlay");
    if (event.target !== modalOverlay) return;
    setModalIsVisible(false);
  };

  const handleNextCaseClick = (i: number) => {
    setCaseIndex(i);
  };

  return (
    <>
      <Heading className="case-study__heading" slug={slug}>
        {heading}
      </Heading>
      <Headline className="case-study__headline">{headline}</Headline>
      <CaseList
        list={caseList}
        onCaseClick={handleCaseClick}
        onCasePress={handleCasePress}
        lang={lang}
      />

      {caseList.map((item, i) => {
        const nextCaseIndex = i === caseList.length - 1 ? 0 : i + 1;

        return (
          <CSSTransition
            key={i}
            in={modalIsVisible && i === caseIndex}
            timeout={timeout}
            className="cs-modal"
            mountOnEnter
            unmountOnExit
          >
            <CaseStudyModal
              lang={lang}
              onModalClose={handleModalClose}
              onOverlayClick={handleOverlayClick}
              caseData={item}
              caseIndex={i}
              nextCase={caseList[nextCaseIndex]}
              nextCaseIndex={nextCaseIndex}
              onNextCaseClick={handleNextCaseClick}
            />
          </CSSTransition>
        );
      })}
    </>
  );
}

type Props = {
  lang: Language;
};

export default CaseStudy;
